import * as React from 'react';
import {
  classNamesFunction,
  FocusZone,
  FocusZoneDirection,
  getId,
  IFocusZone,
  warnMutuallyExclusive
} from '@fluentui/react';

import { INavProps, INavStyleProps, INavStyles, INavState, INavLinkGroup, INavLink } from './Nav.types';
import { NavLink } from './NavLink';
import { NavGroup } from './NavGroup';
import { getNavCountInfo, getNavGroupCountStart } from './Nav.utils';

const getClassNames = classNamesFunction<INavStyleProps, INavStyles>();

export class NavBase extends React.Component<INavProps, INavState> {
  private _menuRef: React.RefObject<HTMLDivElement>;
  private _containerRef: React.RefObject<HTMLDivElement>;
  private _focusRef: React.RefObject<IFocusZone>;
  private _observer: IntersectionObserver;
  private _animationFrameID: number | undefined;
  private _mql: MediaQueryList;

  constructor(props: INavProps) {
    super(props);

    warnMutuallyExclusive<INavProps>('NavBase', this.props, {
      isNavCollapsed: 'defaultIsNavCollapsed'
    });

    this.state = {
      isNavCollapsed: !!(props.isNavCollapsed !== undefined ? props.isNavCollapsed : props.defaultIsNavCollapsed),
      shouldScroll: false,
      zoomLevel: window.devicePixelRatio
    };

    this._menuRef = React.createRef<HTMLDivElement>();
    this._containerRef = React.createRef<HTMLDivElement>();
    this._focusRef = React.createRef<IFocusZone>();
    this._onNavCollapseClicked = this._onNavCollapseClicked.bind(this);
    this._setScrollLayout = this._setScrollLayout.bind(this);
    this._updatePixelRatio = this._updatePixelRatio.bind(this);
  }

  public render(): JSX.Element {
    const {
      groups,
      enableCustomization,
      showMore,
      styles,
      showMoreLinkProps,
      editLinkProps,
      collapseNavLinkProps,
      theme,
      hideCollapseLink
    } = this.props;
    const { shouldScroll, zoomLevel } = this.state;

    const uniqueId = getId('nav-');

    if (
      groups.some((group: INavLinkGroup) =>
        group.links.some((link: INavLink) => link.links?.some((nestedLink: INavLink) => nestedLink.links ?? false))
      )
    ) {
      console.warn(
        'It looks like you have more than 2 levels of nested INavLinks. ' +
          'Consider restructuring your Nav as the M365 Nav will only render up to 2 levels of nested INavLinks'
      );
    }

    const isNavCollapsed =
      this.props.isNavCollapsed === undefined ? this.state.isNavCollapsed : this.props.isNavCollapsed;

    const classNames = getClassNames(styles, { isNavCollapsed, shouldScroll, zoomLevel, theme: theme! });

    const { navItemTotal, editNavIndex, ...rest } = getNavCountInfo(groups, enableCustomization, showMore);

    let navGroupStartIndex = rest.navGroupStartIndex;

    return (
      <div className={classNames.root} role="presentation">
        <div className={classNames.navWrapper} role="presentation">
          {!hideCollapseLink && (
            <NavLink
              primaryIconName={'GlobalNavButton'}
              {...collapseNavLinkProps}
              onClick={this._onNavCollapseClicked}
              aria-controls={uniqueId}
              aria-expanded={!isNavCollapsed}
              styles={classNames.subComponentStyles.collapseNavButtonStyles}
            />
          )}
          <nav role="navigation" id={uniqueId} className={classNames.navContainer} ref={this._containerRef}>
            {/* We need this additional div to calculate whether or not scrolling is required now that the collapse
            button is outside the focus zone */}
            <div ref={this._menuRef} role="presentation">
              <FocusZone
                isCircularNavigation
                direction={FocusZoneDirection.vertical}
                as="ul"
                role="menubar"
                aria-orientation="vertical"
                className={classNames.navGroup}
                componentRef={this._focusRef}
              >
                {groups.map((group: INavLinkGroup, groupIndex: number, array: INavLinkGroup[]) => {
                  // Call getNavGroupCountStart to properly calculate the start index of the next group
                  navGroupStartIndex = getNavGroupCountStart(navGroupStartIndex, groupIndex, array);
                  return (
                    <NavGroup
                      {...group}
                      groupIndex={groupIndex}
                      groupName={group.name}
                      isNavCollapsed={isNavCollapsed}
                      navRef={this._containerRef}
                      focusZoneRef={this._focusRef}
                      itemStartIndex={navGroupStartIndex}
                      itemTotal={navItemTotal}
                      // explicitly setting key here to appease linter as it doesn't get that it's set due to spread
                      key={group.key}
                    />
                  );
                })}

                {enableCustomization && (
                  // If enableCustomization
                  <>
                    <li role="presentation" className={classNames.navGroupDivider} />
                    <li role="presentation">
                      <NavLink
                        primaryIconName={'Edit'}
                        role="menuitem"
                        {...editLinkProps}
                        aria-setsize={navItemTotal}
                        aria-posinset={editNavIndex}
                      />
                    </li>
                    {showMore && (
                      <li role="presentation">
                        <NavLink
                          primaryIconName={'More'}
                          role="menuitem"
                          {...showMoreLinkProps}
                          aria-setsize={navItemTotal}
                          aria-posinset={navItemTotal}
                        />
                      </li>
                    )}
                  </>
                )}
              </FocusZone>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  public componentDidMount(): void {
    this._createObserver();
    this._updatePixelRatio();
  }

  public componentWillUnmount(): void {
    this._observer.unobserve(this._menuRef.current!);
    if (this._mql) {
      this._mql.onchange = null;
    }
  }

  private _createObserver(): void {
    // ref use, call after mount.
    this._observer = new IntersectionObserver(this._setScrollLayout, {
      root: this._containerRef.current,
      // Threshold lowered because Chromium floating point error causes scrollbar to incorrectly appear when zoomed.
      // See Chromium bug for more info: https://bugs.chromium.org/p/chromium/issues/detail?id=737228
      threshold: 0.995
    });
    this._observer.observe(this._menuRef.current!);
  }

  private _updatePixelRatio() {
    if (this._animationFrameID !== undefined) {
      cancelAnimationFrame(this._animationFrameID);
    }

    this._animationFrameID = requestAnimationFrame(() => {
      if (this._mql) {
        this._mql.onchange = null;
      }
      this._mql = matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`);
      this._mql.onchange = this._updatePixelRatio;
      this._animationFrameID = undefined;
    });

    this.setState({ zoomLevel: window.devicePixelRatio });
  }

  private _setScrollLayout(entries: IntersectionObserverEntry[]): void {
    // if we need to scroll set the internal state accordingly only if the state has actually changed
    // Threshold lowered because Chromium floating point error causes scrollbar to incorrectly appear when zoomed.
    // See Chromium bug for more info: https://bugs.chromium.org/p/chromium/issues/detail?id=737228
    const shouldScroll = entries[0].intersectionRatio < 0.995;
    if (shouldScroll !== this.state.shouldScroll) {
      this.setState({ shouldScroll });
    }
  }

  private _onNavCollapseClicked(ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void {
    // inform the caller about the collapse event

    // collapse this into a single call by extending interface and overriding sig
    if (this.props.onNavCollapsed) {
      this.props.onNavCollapsed(!this.state.isNavCollapsed);
    }

    // additionally call onClick if it was provided in props
    if (this.props.collapseNavLinkProps && this.props.collapseNavLinkProps.onClick) {
      this.props.collapseNavLinkProps.onClick(ev);
    }

    if (this.props.isNavCollapsed === undefined) {
      this.setState({
        isNavCollapsed: !this.state.isNavCollapsed
      });
    }
  }
}
