import { styled } from '@fluentui/react';

import { getNavGroupStyles } from './NavGroup.styles';
import { INavGroupProps, INavGroupStyleProps, INavGroupStyles } from './NavGroup.types';
import { NavGroupBase } from './NavGroupBase';

export const NavGroup: React.FunctionComponent<INavGroupProps> = styled<
  INavGroupProps,
  INavGroupStyleProps,
  INavGroupStyles
>(NavGroupBase, getNavGroupStyles, undefined, { scope: 'NavGroup' });
