import { FontSizes, getFocusStyle, HighContrastSelector } from '@fluentui/react';
import { throwOnUndefinedColor } from '@m365-admin/customizations';
import { getRTLFlipOptOut } from '@m365-admin/utilities';

import { INavLinkStyles, INavLinkStyleProps } from './NavLink.types';
import { navItemHeight } from './Nav.styles';

const navIconSize = FontSizes.icon;
const navChildItemHeight = 32;
const componentName: string = 'NavLink';

export const getNavLinkStyles = (props: INavLinkStyleProps): INavLinkStyles => {
  const {
    isNavCollapsed,
    isExpanded,
    hasNestedMenu,
    isSelected,
    hasSelectedNestedLink,
    isNested,
    theme,
    hasIcon,
    preserveIconSpace,
    isCollapsibleSection
  } = props;
  const selectedMarkerOffset = !isNavCollapsed && isNested ? '34px' : '4px';
  return {
    root: [
      theme.fonts.medium,
      getFocusStyle(theme),
      {
        minHeight: isNested ? navChildItemHeight : navItemHeight,
        color: theme.semanticColors.buttonText,
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        width: '100%',
        background: 'transparent',
        border: 'none',
        padding: 0,
        textAlign: 'inherit',
        ':hover': {
          backgroundColor: throwOnUndefinedColor(
            theme.semanticColors.navItemBackgroundHovered,
            'navItemBackgroundHovered',
            componentName
          )
        },
        ':active': {
          backgroundColor: throwOnUndefinedColor(
            theme.semanticColors.navItemBackgroundPressed,
            'navItemBackgroundPressed',
            componentName
          )
        }
      }
    ],
    iconContainer: [
      {
        display: 'flex',
        flex: `0 0 ${isNavCollapsed && isCollapsibleSection ? 12
          : (hasIcon || isNested || preserveIconSpace ? 48 : 16)}px`,
        alignItems: 'center',
        justifyContent: 'center',
        '::before': [
          {
            content: '" "',
            position: 'absolute',
            left: selectedMarkerOffset,
            width: '4px',
            height: '24px',
            backgroundColor: theme.palette.accent,
            opacity: 0,
            transition: 'opacity 300ms',
            [HighContrastSelector]: {
              backgroundColor: 'Highlight'
            }
          },
          isNested && {
            height: '18px',
            top: '7px'
          },

          // in a collapsibleSection, hide the indicator when it's nested components are expanded
          (!(isCollapsibleSection && isExpanded) &&
            (
              // Nav is open, L2 menu collapsed, L2 has a selected link => true
              (!isNavCollapsed && !isExpanded && hasSelectedNestedLink) ||
              // Nav is open, is an L2 menu, is selected => true
              (!isNavCollapsed && !hasNestedMenu && isSelected) ||
              // Nav is closed, is selected regardless of L1 or L2 => true
              (isNavCollapsed && isSelected)
            )
          ) && {
            opacity: 1
          }
        ],
        '*[dir="rtl"] &::before': {
          right: `${selectedMarkerOffset} ${getRTLFlipOptOut()}`
        }
      },
      isNavCollapsed &&
      isNested && {
        flex: '0 0 12px'
      }
    ],
    icon: {
      fontSize: navIconSize,
      color: theme.palette.neutralPrimary
    },
    secondaryIcon: [
      {
        flex: `0 0 ${hasNestedMenu ? '48px' : '16px'}`,
        fontSize: theme.fonts.small.fontSize,
        color: theme.palette.neutralPrimary,
        transition: 'transform 200ms',
        textAlign: 'center'
      },
      isExpanded && {
        transform: 'rotate(-180deg)'
      }
    ],
    text: [
      {
        flex: '1 1 auto',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        color: theme.palette.neutralPrimary,
        pointerEvents: 'none'
      }
    ]
  };
};
