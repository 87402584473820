import * as React from 'react';
import { styled, classNamesFunction, FocusZone, getId, IFocusZone, KeyCodes } from '@fluentui/react';
import { warnDependent } from '@m365-admin/utilities';

import { INavLink } from './Nav.types';
import { getNavLinkGroupStyles } from './NavLinkGroup.styles';
import {
  INavLinkGroupProps,
  INavLinkGroupStyleProps,
  INavLinkGroupStyles,
  INavLinkGroupStates
} from './NavLinkGroup.types';
import { NavLink } from './NavLink';

/**
 * In order to avoid circular dependecy issues for consumers using AMD,
 * we are moving the base and main component into the same file. This avoids
 * the bundling issue AMD users are hitting. However, for most components this
 * will not be appropriate and is not recommended from a structuring stand point.
 */

const getClassNames = classNamesFunction<INavLinkGroupStyleProps, INavLinkGroupStyles>();
let idIncrementer = 0;

export class NavLinkGroupBase extends React.Component<INavLinkGroupProps, INavLinkGroupStates> {
  private navLinkGroupRef: React.RefObject<HTMLDivElement>;
  private navRootRef: React.RefObject<HTMLDivElement>;
  private instanceNumber: number;
  private uniqueId: string;

  constructor(props: INavLinkGroupProps) {
    super(props);

    this.state = {
      isExpanded: !!(props.isExpanded !== undefined ? props.isExpanded : props.defaultIsExpanded),
      isKeyboardExpanded: false
    };
    this.navLinkGroupRef = React.createRef<HTMLDivElement>();
    this.navRootRef = React.createRef<HTMLDivElement>();
    this._onLinkClicked = this._onLinkClicked.bind(this);
    this._offsetUpdated = this._offsetUpdated.bind(this);
    this._nestedNavBlur = this._nestedNavBlur.bind(this);
    this._escapeSubNavFocus = this._escapeSubNavFocus.bind(this);
    this.instanceNumber = idIncrementer++;
    this.uniqueId = props.isGroupSectionHeader ? getId('Group_') : getId('L1Content_');
  }

  public render(): JSX.Element {
    const {
      link,
      isNavCollapsed,
      styles,
      theme,
      itemTotal,
      itemStartIndex,
      isCollapsibleSection,
      isGroupSectionHeader
    } = this.props;
    const { isKeyboardExpanded } = this.state;

    const isExpanded = this.props.isExpanded === undefined ? this.state.isExpanded : this.props.isExpanded;

    const hasSelectedNestedLinkInGroup =
      link.links &&
      link.links.some((nestedlink: INavLink) => nestedlink.isSelected || this._isNestedLinkSelected(nestedlink));

    // Is L1 header under group section header
    const isL1UnderGroup = isCollapsibleSection && !isGroupSectionHeader;

    const classNames = getClassNames(styles, {
      isExpanded: isExpanded,
      isNavCollapsed: isNavCollapsed!,
      isKeyboardExpanded: isKeyboardExpanded!,
      theme: theme!,
      isCollapsibleSection: isL1UnderGroup!
    });
    const NestedComponent = isNavCollapsed && isKeyboardExpanded && !isL1UnderGroup ? FocusZone : 'ul';

    const menuId = this.uniqueId + 'menu_id' + this.instanceNumber;
    const linkId = this.uniqueId + '_id' + this.instanceNumber;

    return (
      <div
        className={classNames.root}
        role="presentation"
        {...(isNavCollapsed && link.links && { onMouseEnter: this._offsetUpdated, ref: this.navRootRef })}
      >
        <NavLink
          item={link}
          role="menuitem"
          primaryIconName={link.icon}
          {...link}
          isNavCollapsed={isNavCollapsed}
          onClick={this._onLinkClicked}
          aria-controls={menuId}
          aria-expanded={isExpanded}
          {...(isNavCollapsed &&
            !isL1UnderGroup &&
            link.links && {
              'aria-haspopup': true,
              'aria-expanded': isKeyboardExpanded
            })}
          isSelected={hasSelectedNestedLinkInGroup}
          hasSelectedNestedLink={hasSelectedNestedLinkInGroup}
          hasNestedMenu={true}
          isCollapsibleSection={isL1UnderGroup}
          isExpanded={isExpanded}
          id={linkId}
          aria-setsize={itemTotal}
          aria-posinset={itemStartIndex}
          {...(!isExpanded && hasSelectedNestedLinkInGroup && { 'aria-current': 'page' })}
        />
        {link.links && (
          <div
            className={classNames.nestedNav}
            role="presentation"
            {...(isNavCollapsed && { ref: this.navLinkGroupRef, 'data-is-focusable': false })}
          >
            {isNavCollapsed && !isL1UnderGroup && (
              <NavLink
                isNavCollapsed={isNavCollapsed}
                name={link.name}
                data-is-focusable={false}
                aria-hidden={true}
                primaryIconName={link.icon}
                disabled
                styles={{
                  root: classNames.nestedNavHeaderItem,
                  text: classNames.nestedNavHeaderItemText,
                  icon: classNames.nestedNavHeaderItemIcon
                }}
              />
            )}
            <div className={classNames.nestedNavLinksWrapper} role="presentation">
              {/** If you apply backdrop-filter to an element with box-shadow, the filter will also
               apply to the shadow, so those elements need to be separated. This one has the shadow.*/}
              <NestedComponent
                className={classNames.nestedNavLinks}
                aria-labelledby={linkId}
                role="menu"
                id={menuId}
                {...(isKeyboardExpanded &&
                  isNavCollapsed && {
                    componentRef: this._keyboardFocusSubNav,
                    onKeyDown: this._escapeSubNavFocus,
                    isCircularNavigation: !isL1UnderGroup,
                    as: 'ul',
                    onBlur: this._nestedNavBlur
                  })}
              >
                {link.links.map((nestedLink: INavLink, index: number) => {
                  // warn if developer passes in onExpanded without dependent links prop
                  warnDependent('INavLink', nestedLink, { onExpanded: 'links' });
                  const hasSelectedNestedLink = this._isNestedLinkSelected(nestedLink);
                  return (
                    <li role="presentation" key={nestedLink.key}>
                      {nestedLink.links ? (
                        <NavLinkGroup
                          {...nestedLink}
                          styles={nestedLink.navLinkGroupStyles}
                          isNavCollapsed={isNavCollapsed}
                          link={nestedLink}
                          hasNestedMenu={true}
                          hasSelectedNestedLink={hasSelectedNestedLink}
                          onCollapse={this.props.onCollapse}
                          navRef={this.props.navRef}
                          focusZoneRef={this.props.focusZoneRef}
                          itemStartIndex={index + 1}
                          itemTotal={link.links!.length}
                          isCollapsibleSection={isCollapsibleSection}
                        />
                      ) : (
                        <NavLink
                          item={nestedLink}
                          aria-setsize={link.links!.length}
                          aria-posinset={index + 1}
                          role="menuitem"
                          primaryIconName={nestedLink.icon}
                          {...nestedLink}
                          isNavCollapsed={isNavCollapsed}
                          {...(nestedLink.isSelected && { 'aria-current': 'page' })}
                          hasNestedMenu={false}
                          hasSelectedNestedLink={false}
                          isNested={isGroupSectionHeader ? false : true}
                          isParentExpanded={isExpanded}
                          isCollapsibleSection={isCollapsibleSection}
                          styles={{
                            iconContainer: classNames.nestedNavLinkIconContainer
                          }}
                        />
                      )}
                    </li>
                  );
                })}
              </NestedComponent>
            </div>
          </div>
        )}
      </div>
    );
  }

  private _onLinkClicked(ev: React.MouseEvent<HTMLElement>, item: INavLink): void {
    this.setState(
      {
        // we should only set isKeyboardExpanded when we're in collapsed mode (emulates isExpanded)
        ...(this.props.isNavCollapsed && { isKeyboardExpanded: !this.state.isKeyboardExpanded }),
        // only set internal state if isExpanded is not set meaning we're in unmanaged mode
        ...(this.props.isExpanded === undefined && {
          isExpanded: !this.state.isExpanded
        })
      },
      () => {
        if (this.props.onCollapse) {
          this.props.onCollapse();
        }
      }
    );

    if (this.props.isNavCollapsed) {
      this._offsetUpdated();
    }

    if (this.props.onExpanded) {
      this.props.onExpanded(!this.state.isExpanded);
    }

    if (this.props.link.onClick) {
      this.props.link.onClick(ev, item);
    }
  }

  // We're using the ref callback to focus the element so we can guarantee the element exists
  private _keyboardFocusSubNav(focusZone: IFocusZone): void {
    if (focusZone) {
      focusZone.focus(true);
    }
  }

  private _escapeSubNavFocus(event: React.KeyboardEvent<HTMLElement>): void {
    if (event.which === KeyCodes.escape) {
      this.setState({
        isKeyboardExpanded: false
      });
      if (this.props.focusZoneRef.current) {
        this.props.focusZoneRef.current.focus();
      }
    }
  }

  private _nestedNavBlur(event: React.FocusEvent<HTMLElement>): void {
    let relatedTarget = event.relatedTarget;
    if (event.relatedTarget === null) {
      // In IE11, due to lack of support, event.relatedTarget is always
      // null making every onBlur call to be "outside" of the Nav
      // even when it's not. Using document.activeElement is another way
      // for us to be able to get what the relatedTarget without relying
      // on the event
      relatedTarget = document.activeElement as Element;
    }
    if (!event.currentTarget.contains(relatedTarget as HTMLElement)) {
      this.setState({
        isKeyboardExpanded: false
      });
    }
  }

  // calculate the offset due to scroll so we always position the sub nav correctly
  private _offsetUpdated(_ev?: React.MouseEvent<HTMLElement>): void {
    if (this.navRootRef.current && this.navLinkGroupRef.current && this.props.navRef.current) {
      this.navLinkGroupRef.current.style.top =
        this.navRootRef.current.offsetTop - this.props.navRef.current.scrollTop + 'px';
    }
  }

  private _isNestedLinkSelected(link: INavLink): boolean {
    return (
      link &&
      !!link.links &&
      link.links.some((childLink: INavLink) => {
        return !!childLink && !!childLink.isSelected;
      })
    );
  }
}

export const NavLinkGroup: React.FunctionComponent<INavLinkGroupProps> = styled<
  INavLinkGroupProps,
  INavLinkGroupStyleProps,
  INavLinkGroupStyles
>(NavLinkGroupBase, getNavLinkGroupStyles, undefined, { scope: 'NavLinkGroup' });
