import * as React from 'react';
import { TeachingBubble, DirectionalHint } from '@fluentui/react';
import { INavTeachingBubbleProps } from './NavTeachingBubble.types';

export class NavTeachingBubbleBase extends React.Component<INavTeachingBubbleProps> {
  public render(): JSX.Element {
    const { calloutProps, ...teachingBubbleProps } = this.props;

    return (
      <TeachingBubble
        hasCondensedHeadline={true}
        hasSmallHeadline={true}
        hasCloseIcon={true}
        isWide={false}
        {...teachingBubbleProps}
        calloutProps={{
          beakWidth: 12,
          directionalHint: DirectionalHint.bottomLeftEdge,
          directionalHintFixed: true,
          ...calloutProps
        }}
      />
    );
  }
}
